export const Webp = {
    computed: {
        isSupportWebp: function () {
            return (document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0)
        }
    },
    methods: {
        webp: function (url) {
            if(process.env.NODE_ENV !== 'development' && this.isSupportWebp) {
                return url.replace(/(\.(jpe?g|png))/g, ".webp")
            }
            return url;
        }
    }
}