import Vue from 'vue'
import Vuex from 'vuex'
import Main from '../Main.vue'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import VueI18n from 'vue-i18n'
import VueAnalytics from 'vue-analytics'
import {Webp} from '../mixins/webp'
import VueMeta from "vue-meta";
import dayjs from 'dayjs'
import 'dayjs/locale/vi'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import Youtube from 'vue-youtube'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueScrollTo)
Vue.use(VueI18n)
Vue.mixin(Webp)
Vue.use(VueMeta)
Vue.use(Youtube)

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

dayjs.extend(advancedFormat)
Vue.filter('formatDate', function(value, format, locale = 'vi') {
  if (value) {
    return dayjs(String(value)).locale(locale).format(format)
  }
})

function loadLocaleMessages () {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: localStorage.locale || 'vi',
  fallbackLocale: 'vi',
  messages: loadLocaleMessages(),
});

const router = new VueRouter({
  mode: 'history',
  relative: true,
  base: '/',
  routes: [
    { path: '/', component:  () => import(/* webpackChunkName: "app" */ "../App") },
    { path: '/corporate', component: () => import(/* webpackChunkName: "corporate" */ "../Corporate") },
    { path: '/product/:product', component: () => import(/* webpackChunkName: "product" */ "../Detail") },
    { path: '/news', component: () => import(/* webpackChunkName: "news" */ "../News") },
    { path: '/news/:slug', component: () => import(/* webpackChunkName: "detail" */ "../NewsDetail") },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  }
})

Vue.use(VueAnalytics, {
  id: 'UA-155054931-1',
  router,
  autoTracking: {
    exception: true
  },
  debug: {
    //enabled: !isProd,
    //sendHitTask: isProd
  }
})

const store = new Vuex.Store({
  state: {
    // for POPUP
    min: localStorage.minPopup || '',
    sticker: false,
    stickerID: 1,
  },
  mutations: {
    setMin: (state, payload) => { 
      state.min = payload.min
      state.sticker = payload.sticker
    },
    setStickerID: (state, id) => {state.stickerID = id}
  },
  actions: {
    minimizePopup: ({ commit }) => {
      commit('setMin', {min: 'min', sticker: false})
      localStorage.minPopup = 'min'
    }, 
    maximizePopup: ({ commit }) => {
      commit('setMin', {min: '', sticker: false})
      localStorage.minPopup = ''
    },
  },
})

new Vue({
  store,
  i18n,
  router,
  render: h => h(Main),
}).$mount('#main')
