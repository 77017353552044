<template>
  <div id="main">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'main',
    metaInfo () {
      return {
        title: 'Koikeya | Snack – Cay ngon tan chảy, bốc liền liền tay',
        titleTemplate: '%s',
        meta: [
          { name: 'description', content: 'Với dòng sản phẩm snack cay, Koike-ya Inc. tự hào nắm giữ thị phần hàng đầu Nhật Bản, và cũng là dòng bánh snack rất được yêu chuộng tại nhiều quốc gia.', vmid: 'description' },
          { property: 'og:title', content: 'Koikeya', vmid: 'og:title' },
          { property: 'og:description', content: 'Với dòng sản phẩm snack cay, Koike-ya Inc. tự hào nắm giữ thị phần hàng đầu Nhật Bản, và cũng là dòng bánh snack rất được yêu chuộng tại nhiều quốc gia.', vmid: 'og:description' },
          { property: 'og:url', content: 'http://koike-ya.vn', vmid: 'og:url' },
          { property: 'og:type', content: 'website', vmid: 'og:type' }
        ]
      }
    },
  }
</script>